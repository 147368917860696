import React from 'react';
import { useTable, useSortBy, usePagination } from "react-table";

export function Table({ columns, data, sortColumns, onRowClick }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        toggleSortBy,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({ columns, data, initialState: { sortBy: sortColumns, pageIndex: 0, pageSize: 100 } }, useSortBy, usePagination);

    const handleSortBy = (add, column) => {
        const desc = column.isSorted ? (column.isSortedDesc ? false : true) : false;

        if (add) {
            let found = false;
            for (let i = 0; i < sortColumns.length; i++) {
                if (sortColumns[i].id == column.id) {
                    found = true;
                    sortColumns[i].desc = desc;
                }
            }
    
            if (!found) {
                sortColumns.push({id: column.id, desc})
            }
        } else {
            sortColumns.length = 0;
            sortColumns.push({id: column.id, desc})
        }

        toggleSortBy(column.id, desc, add)
    }

    return (
        <div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} onClick={e => handleSortBy(e.shiftKey, column)}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={() => onRowClick(row)}>
                                    {row.cells.map(cell => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </table>
            <div>
                <button disabled={!canPreviousPage} onClick={() => gotoPage(0)}>{"<<"}</button>
                <button disabled={!canPreviousPage} onClick={previousPage}>{"<"}</button>
                <span>Page {pageIndex} of {pageCount}</span>
                <button disabled={!canNextPage} onClick={nextPage}>{">"}</button>
                <button disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)}>{">>"}</button>
            </div>
        </div>
    )
}

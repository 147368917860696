
import React from "react";
import PropTypes from "prop-types";

import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

import { ChartCanvas, Chart } from "react-stockcharts";
import {
	BarSeries,
	AreaSeries,
	CandlestickSeries,
	LineSeries,
	StraightLine, // for zones
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
	CrossHairCursor,
	EdgeIndicator,
	CurrentCoordinate,
	MouseCoordinateX,
	MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";
import { TrendLine } from "react-stockcharts/lib/interactive";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import {
	OHLCTooltip,
	MovingAverageTooltip,
  ToolTipText,
  SingleValueTooltip,
} from "react-stockcharts/lib/tooltip";
import { ema, macd, sma } from "react-stockcharts/lib/indicator";
import { fitWidth } from "react-stockcharts/lib/helper";
import { last } from "react-stockcharts/lib/utils";
import { LabelAnnotation, Annotate } from "react-stockcharts/lib/annotation";

class CandleStickChartWithEdge extends React.Component {
	render() {
		const ema20 = ema()
			.id(0)
			.options({ windowSize: 20 })
			.merge((d, c) => { d.ema20 = c; })
			.accessor(d => d.ema20);

		const ema50 = ema()
			.id(1)
			.options({ windowSize: 50 })
			.merge((d, c) => { d.ema50 = c; })
			.accessor(d => d.ema50);

		const ema100 = ema()
			.id(2)
			.options({ windowSize: 100 })
			.merge((d, c) => { d.ema100 = c; })
			.accessor(d => d.ema100);

		const ema200 = ema()
			.id(3)
			.options({ windowSize: 200 })
			.merge((d, c) => { d.ema200 = c; })
			.accessor(d => d.ema200);

		const smaVolume70 = sma()
			.id(4)
			.options({ windowSize: 70, sourcePath: "volume" })
			.merge((d, c) => { d.smaVolume70 = c; })
			.accessor(d => d.smaVolume70);

		const { type, data: initialData, width, ratio } = this.props;

		const quotes = initialData.map(d => Object.assign({}, 
			d.quote, 
			{ 
				confidence: d.output.tendency.confidence,
				direction: d.output.tendency.direction,
				stoch: d.explanation.stoch.explanation.stoch,
				rsi: d.explanation.rsi.explanation.rsi,
				macd: d.explanation.macd.explanation,
        ideal_bb: d.explanation.ideal_bb.output,
				orig: d,
			}));

		const calculatedData = ema20(ema50(ema100(ema200(smaVolume70(quotes)))));
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor(d => d.date);
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(calculatedData);

		const start = xAccessor(last(data));
		const end = xAccessor(data[Math.max(0, data.length - 300)]);
		const xExtents = [start, end];

		const zoneData = initialData[initialData.length - 1].explanation.zone.explanation.sr_zones;
		const zones = zoneData.map(zd => <StraightLine
			yValue={zd.low + (zd.high - zd.low) / 2}
			stroke="#000000"
			strokeWidth={zd.high - zd.low}
			opacity={zd.strength / 2000.0}
		/>
		);

		let trends = [];

		if (initialData.length > 1) {
			const explanation = initialData[initialData.length - 1].explanation;
			const trendlineExpl = explanation.trendline.explanation.state.trendline;

			const upTrendY1 = trendlineExpl.high_value;
			const upTrendX1 = initialData.length - 2;
			const upTrendY2 = upTrendY1 + Math.atan(trendlineExpl.high_slope);
			const upTrendX2 = upTrendX1 + 1;

			const downTrendY1 = trendlineExpl.low_value;
			const downTrendX1 = initialData.length - 2;
			const downTrendY2 = downTrendY1 + Math.atan(trendlineExpl.low_slope);
			const downTrendX2 = downTrendX1 + 1;

			trends = [
				{ start: [upTrendX1, upTrendY1], end: [upTrendX2, upTrendY2], type: "XLINE", appearance: { stroke: "red", strokeOpacity: 0.2 } },
				{ start: [downTrendX1, downTrendY1], end: [downTrendX2, downTrendY2], type: "XLINE", appearance: { stroke: "green", strokeOpacity: 0.2 } }
			];
		}

		return (
			<ChartCanvas height={800}
				ratio={ratio}
				width={width}
				margin={{ left: 50, right: 50, top: 20, bottom: 20 }}
				type={type}
				seriesName={this.props.symbol}
				data={data}
				xScale={xScale}
				xAccessor={xAccessor}
				displayXAccessor={displayXAccessor}
				xExtents={xExtents}
			>
				<Chart id={1}
					height={300}
					yPan yExtents={[d => [d.high, d.low], ema20.accessor(), ema50.accessor(), ema100.accessor(), ema200.accessor()]}
					padding={{ top: 10, bottom: 20 }}
				>

					<XAxis axisAt="top" orient="top" flexTicks />
					<YAxis axisAt="right" orient="right" ticks={5} />

					<CandlestickSeries />

					<LineSeries yAccessor={ema20.accessor()} stroke={ema20.stroke()} highlightOnHover />
					<LineSeries yAccessor={ema50.accessor()} stroke={ema50.stroke()} highlightOnHover />
					<LineSeries yAccessor={ema100.accessor()} stroke={ema100.stroke()} highlightOnHover />
					<LineSeries yAccessor={ema200.accessor()} stroke={ema200.stroke()} highlightOnHover />

					<CurrentCoordinate yAccessor={ema20.accessor()} fill={ema20.stroke()} />
					<CurrentCoordinate yAccessor={ema50.accessor()} fill={ema50.stroke()} />
					<CurrentCoordinate yAccessor={ema100.accessor()} fill={ema100.stroke()} />
					<CurrentCoordinate yAccessor={ema200.accessor()} fill={ema200.stroke()} />

					{zones}

					<TrendLine trends={trends} enabled={false} />

					<EdgeIndicator itemType="last" orient="right" edgeAt="right"
						yAccessor={d => d.close} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"} />

					<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.orig.output.rrr.sell_profit_price} fill="#4C765D" />
					<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={d => d.orig.output.rrr.stop_loss_price} fill="#830000" />

					<MouseCoordinateX
						at="top"
						orient="top"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".2f")} />

					<OHLCTooltip origin={[-40, 0]} />
				</Chart>
				<Chart id={2}
					yExtents={[[0, d => d.volume], smaVolume70.accessor()]}
					height={75} origin={(w, h) => [0, h - 550]}
				>
					<YAxis axisAt="left" orient="left" ticks={5} tickFormat={format(".2s")} />

					<BarSeries yAccessor={d => d.volume} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"} opacity={0.2} stroke={false} />
					<AreaSeries yAccessor={smaVolume70.accessor()} stroke={smaVolume70.stroke()} fill={smaVolume70.fill()} opacity={0.1} />

					<CurrentCoordinate yAccessor={smaVolume70.accessor()} fill={smaVolume70.stroke()} />
					<CurrentCoordinate yAccessor={d => d.volume} fill="#9B0A47" />
				</Chart>
				<Chart id={3}
					yExtents={[0, d => d.confidence]}
					height={75} origin={(w, h) => [0, h - 475]}
				>
          <SingleValueTooltip origin={[0, 10]} yLabel={"Confidence"} yDisplayFormat={format(".4f")} yAccessor={d => d.confidence}/>

					<YAxis axisAt="right" orient="right" ticks={5} tickFormat={format(".2s")} />

					<BarSeries yAccessor={d => d.confidence} stroke={false} fill={d => d.direction == "Up" ? "#6BA583" : "#FF0000"} highlightOnHover />
					<CurrentCoordinate yAccessor={d => d.confidence} fill="#9B0A47" />

					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".2f")} />
				</Chart>
				<Chart id={4}
					yExtents={[0, 100]}
					height={75} origin={(w, h) => [0, h - 400]}
				>
          <SingleValueTooltip origin={[0, 10]} yLabel={"RSI"} yAccessor={d => d.rsi}/>

					<YAxis axisAt="right" orient="right" ticks={5} tickFormat={format(".2s")} />

					<LineSeries yAccessor={d => d.rsi} stroke={ema20.stroke()} highlightOnHover />
					<CurrentCoordinate yAccessor={d => d.rsi} fill="#9B0A47" />

					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".2f")} />
				</Chart>
				<Chart id={5}
					yExtents={[0, 100]}
					height={75} origin={(w, h) => [0, h - 325]}
				>
          <SingleValueTooltip origin={[0, 10]} yLabel={"Stoch"} yAccessor={d => d.stoch.value}/>

					<YAxis axisAt="right" orient="right" ticks={5} tickFormat={format(".2s")} />

					<LineSeries yAccessor={d => d.stoch.value} stroke={ema20.stroke()} highlightOnHover />
					<LineSeries yAccessor={d => d.stoch.signal} stroke={ema50.stroke()} highlightOnHover />
					<CurrentCoordinate yAccessor={d => d.stoch.value} fill="#9B0A47" />

					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".2f")} />
				</Chart>
				<Chart id={6}
					yExtents={[d => d.macd.macd, d => macd.signal]}
					height={75} origin={(w, h) => [0, h - 250]}
				>
          <SingleValueTooltip origin={[0, 10]} yLabel={"MACD"} yAccessor={d => d.macd.macd}/>

					<YAxis axisAt="right" orient="right" ticks={5} tickFormat={format(".2s")} />

					<LineSeries yAccessor={d => d.macd.macd} stroke={ema20.stroke()} highlightOnHover />
					<LineSeries yAccessor={d => d.macd.signal} stroke={ema50.stroke()} highlightOnHover />
					<BarSeries yAccessor={d => d.macd.histo} stroke={false} fill="#9B0A47" highlightOnHover />
					<CurrentCoordinate yAccessor={d => d.macd.macd} fill="#9B0A47" />

					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".2f")} />
				</Chart>
				<Chart id={7}
					yExtents={[0, 1]}
					height={75} origin={(w, h) => [0, h - 175]}
				>
          <SingleValueTooltip origin={[0, 10]} yLabel={"Ideal BB"} yDisplayFormat={format(".4f")} yAccessor={d => d.ideal_bb.confidence}/>

					<XAxis axisAt="bottom" orient="bottom" />
					<YAxis axisAt="right" orient="right" ticks={5} tickFormat={format(".2s")} />

					<BarSeries yAccessor={d => d.ideal_bb.confidence} stroke={false} fill={d => d.ideal_bb.direction == "Up" ? "#6BA583" : "#FF0000"} highlightOnHover />
					<CurrentCoordinate yAccessor={d => d.ideal_bb.confidence} fill="#9B0A47" />

					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".2f")} />
				</Chart>
				<CrossHairCursor />
			</ChartCanvas>
		);
	}
}

/*


*/

CandleStickChartWithEdge.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

CandleStickChartWithEdge.defaultProps = {
	type: "hybrid",
};
CandleStickChartWithEdge = fitWidth(CandleStickChartWithEdge);

export default CandleStickChartWithEdge;
